<div class="inline-editor" id="inlineEditor" [ngClass]="{'inline-datepicker': type==='date'}">
    <div *ngIf="editing || type === 'tagInput'">
        <div [ngSwitch]="type">
            <so-textbox *ngSwitchCase="'text'" [maxLength]="data.max" [placeHolder]="data.label"
                [className]="{'form-error': isError}" [inputControlName]="$any(filterFieldForm).controls['value']"
                (blur)="onBlur($event)" [disabled]="disabled" [id]="data.id + data['key']">
            </so-textbox>
            <so-textbox *ngSwitchCase="'number'" (keyEnter)="checkValuesForNumber($event)"
                [className]="{'form-error': isError}" (blur)="onBlur($event)" [maxLength]="data.max"
                [id]="data.id + data['key']" [directive]="true"
                [inputControlName]="$any(filterFieldForm).controls['value']" [disabled]="disabled"
                [placeHolder]="data.label">
            </so-textbox>
            <so-textbox *ngSwitchCase="'numberDecimal'" (keyEnter)="checkValuesForNumber($event)"
                [className]="{'form-error': isError}" (blur)="onBlur($event)" [maxLength]="data.max"
                [id]="data.id + data['key']" [directive]="true" [customDirective]="true"
                [inputControlName]="$any(filterFieldForm).controls['value']" [disabled]="disabled"
                [placeHolder]="data.label">
            </so-textbox>
            <so-dropdown *ngSwitchCase="'dropdown'" [id]="data.id + data['key']" [className]="'mrg-0'"
                [options]="selectOptions" [value]="'label'" [key]="'value'"
                [inputControlName]="$any(filterFieldForm).controls['value']" [disabled]="disabled"
                (onChange)="onBlur($event)">
            </so-dropdown>
            <so-textarea *ngSwitchCase="'textarea'" [id]="data.id + data['key']"
                [className]="{'form-error': isError}" [rows]="3" [maxLength]="data.max" (blur)="onBlur($event)"
                [inputControlName]="$any(filterFieldForm).controls['value']" [placeHolder]="data.label"
                [disabled]="disabled">
            </so-textarea>


            <so-datepicker *ngSwitchCase="'date'" [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY',showWeek: false }"
                (change)="dateCalendarFieldChanged($event)" (datePickerChange)="dateCalendarChanged($event)"
                [disabled]="disabled" [className]="{'form-error': isError}"
                [inputControlName]="$any(filterFieldForm).controls['value']"
                [id]="'datepicker_'+data.id + data['key']"></so-datepicker>
            <tag-input *ngSwitchCase="'tagInput'" class="watcher-field" name="watchers"
                [animationDuration]="chipsOption.animationDuration" [(ngModel)]="watchers"
                [placeholder]="'Email Address'" [secondaryPlaceholder]="'Email Address'" [disable]="!canEdit"
                [addOnPaste]="chipsOption.addOnPaste" [addOnBlur]="chipsOption.addOnBlur"
                (onAdd)="onInputBlurred($event)" (onPaste)="onInputBlurred($event)"
                [modelAsStrings]="chipsOption.modelAsStrings" [theme]="chipsOption.theme"
                [editable]="chipsOption.editable" [separatorKeyCodes]="chipsOption.separatorKeyCodes"
                [separatorKeys]="chipsOption.separatorKeys" [pasteSplitPattern]="chipsOption.pasteSplitPattern"
                [onlyFromAutocomplete]="true" [allowDupes]="false" #input>
                <ng-template let-item="item" let-index="index">
                    <span *ngIf="item"
                        title=" {{(item?.value?.name) ? (item?.value?.name) : '(' + item?.value?.email + ')'}} ({{item?.value?.email ? (item.value.email): (item?.email)}})">
                        {{(item?.value?.name) ? (item?.value?.name) : ""}}
                        ({{item?.value?.email ? (item.value.email): (item?.email)}})
                    </span>
                    <em class="fas fa-close" (click)="removeTags(input, item, index);"></em>
                </ng-template>


                <tag-input-dropdown [appendToBody]="true" [autocompleteItems]="customerUsers" class="watchers-user">
                    <ng-template let-item="item" let-index="index">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="user-profile-block clearfix">
                                    <div class="user-profile-img">
                                        <img alt="User"
                                            [src]="item.value.thumbnailAwsImageURL || (cdnUrl + 'assets/images/user-image.png')"
                                            [appRetryImage]="item.value.thumbnailAwsImageURL" class="img-responsive">
                                    </div>
                                    <div class="user-desc">
                                        <div class="user-name"
                                            title="{{item.value.name || '(' + item.value.email + ')' }}">
                                            {{item.value.name || "(" + item.value.email + ")"}}
                                        </div>
                                        <a class=" user-email" href="javascript:void(0);" title="{{item.value.email}}">
                                            {{item.value.email}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </tag-input-dropdown>
            </tag-input>
        </div>
        <div *ngIf="isError" class="help-block-inline-edit text-danger browse-invalid">
            <span title="{{isErrorText}}">{{isErrorText}}</span>
        </div>
    </div>
    <div *ngIf="!editing">
        <div title="Click to edit" (click)="edit(filterFieldForm.value.value)"
            (focus)="edit(filterFieldForm.value.value);" tabindex="0" class="inline-edit">

            <span *ngIf="type === 'dropdown';else notDropdown">
                <span [ngSwitch]="data.key">
                    <span *ngSwitchCase="'status'">
                        <span [ngSwitch]="filterFieldForm.controls['value'].value"
                            *ngIf="data?.comp !== 'ticket';else noTicket">
                            <span title="{{updateDeviceStatusName('NotWorking')}}" *ngSwitchCase="'NotWorking'"
                                class="red-status">{{updateDeviceStatusName('NotWorking')}}</span>
                            <span title="{{updateDeviceStatusName('OperationalWithIssue')}}"
                                *ngSwitchCase="'OperationalWithIssue'"
                                class="orange-status">{{updateDeviceStatusName('OperationalWithIssue')}}</span>
                            <span title="{{updateDeviceStatusName('Operational')}}" *ngSwitchDefault
                                class="blue-status">{{updateDeviceStatusName('Operational')}}</span>
                            <em class="fa fa-pencil inline-editable "></em>
                        </span>
                        <ng-template #noTicket>
                            <span [ngSwitch]="filterFieldForm.controls['value'].value">

                                {{filterFieldForm.controls['value'].value}}
                                <em class="fa fa-pencil inline-editable "></em>
                            </span>
                        </ng-template>
                    </span>


                    <span *ngSwitchCase="'installStatus'">
                        <span [ngSwitch]="filterFieldForm.controls['value'].value">
                            <span title="Installed" *ngSwitchCase="'Installed'"
                                class="blue-status">{{data.deviceData.type === 'task' ? 'Done' : 'Installed'}}</span>
                            <span title="In Progress" *ngSwitchCase="'InProgress'" class="orange-status">In
                                Progress</span>
                            <span title="Planned" *ngSwitchDefault class="red-status">Planned</span>
                            <em class="fa fa-pencil inline-editable"></em>
                        </span>
                    </span>
                    <span *ngSwitchCase="'networkSwitchLinkName'">
                        <span
                            *ngIf="data.deviceDetails.networkSwitchLinkName">{{data.deviceDetails.networkSwitchLinkName}}
                            ({{data.deviceDetails.networkSwitchLinkFloorName}})</span>
                        <em class="fa fa-pencil inline-editable"></em>
                    </span>
                    <span *ngSwitchCase="'isArchived'">

                        <span *ngIf="filterFieldForm.controls['value'].value === false">Active</span>
                        <span *ngIf="filterFieldForm.controls['value'].value">Archived</span>
                        <em class="fa fa-pencil inline-editable "></em>

                    </span>
                    <span *ngSwitchDefault>
                        <span>{{setNameFormId()}}
                            <em class="fa fa-pencil inline-editable "></em>
                        </span>
                    </span>
                </span>

            </span>
            <div class="inline-edit-sub-col">
                <ng-template #notDropdown>
                    <span *ngIf="data.key === 'watchers';else notInput">
                        <em class="fa fa-pencil inline-editable"></em>

                    </span>
                    <ng-template #notInput>
                        <span class="textarea-inline"
                            *ngIf="data.key === 'description' || data.key === 'installationNotes' || data.key === 'instructions' || data.key === 'flagNotes';else notextAre">
                            {{filterFieldForm.controls['value'].value}}
                            <em class="fa fa-pencil inline-editable"></em>

                        </span>
                        <ng-template #notextAre>
                            {{filterFieldForm.controls['value'].value}}
                        </ng-template>
                    </ng-template>
                    <span>
                        <em class="fa fa-pencil inline-editable"></em>
                    </span>
                </ng-template> &nbsp;
            </div>
        </div>
    </div>
    <div *ngIf="disabled">
        <so-loader [xsloader]='true'></so-loader>
    </div>
</div>