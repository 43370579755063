<form [formGroup]="buildingForm" class="edit-new-modal" (ngSubmit)="completeFloor(false,true)" novalidate>

    <div class="modal-header">
        <h1> {{sites.name}} <span class="seprator">|</span>  {{buildingDetails.name}} <span
                *ngIf="buildingDetails.floors.length > 0  && buildingForm.controls['floorName'].value !== '' "
                class="seprator">|</span> {{buildingDetails.floors.length > 0 ?
            buildingForm.controls['floorName'].value : ''}}</h1>

        <button data-dismiss="modal" (click)="closeAllPopUpModal()" aria-label="Close" class="close pull-right"
            type="button">
            <i class="fas fa-close"></i>
        </button>
    </div>
    <div class="modal-body" id="modal-body">
        Create and upload Plans for your Building <strong> {{buildingDetails.name}}</strong><span
            *ngIf="buildings.length > 0"> (Building
            {{currentBuildingIndex +1}} of {{buildings.length}})</span> of your Site <strong>
            {{buildingDetails.siteName}}</strong>. Plans can be JPGs, PNGs, or PDFs.
        <div class="edit-building-block ">
            <div class="card-body">
                <div class="row">
                    <div class="col-xl-12 p-0">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="project-header clearfix">

                                </div>
                            </div>
                        </div>
                        <div class="buidling-sidebar">

                            <div class="project-inner-box">
                                <div class="project-header clearfix">
                                    <div class="requester-block ">
                                        <h2 class="pt-4 mb-3"> Plans
                                            <span class="checkbox-label not-allowed archive-check-plan">

                                                <so-checkbox [id]="'checkbox_hide_archive'"
                                                    [isChecked]="showArchivedFloor" [className]="'lbl-check'"
                                                    (onChange)="toggleFloorStatus($event)"
                                                    [label]="'Hide Archived Plans'" [title]="''">
                                                </so-checkbox>
                                            </span>

                                        </h2>
                                    </div>
                                </div>
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <div #autoscroll class="upload-thumb-img">
                                            <div dragula="bag-one" [dragulaModel]="buildingDetails.floors"
                                                (dragulaModelChange)="onDragulaModelChanges($event)">
                                                <div *ngFor="let item of buildingDetails.floors|floorActiveInActive : showArchivedFloor; index as index"
                                                    [attr.id]="item && item.id">
                                                    <div class="card thumb-inner "
                                                        *ngIf="item && !item.isDeleted && item !== 'NODATA'"
                                                        [ngClass]="{'active': selectedFloor && item.id === selectedFloor.id, 'archive' : item && item.isArchived}"
                                                        (click)="selectFloor(item)">
                                                        <div class="thumb-inner-img">
                                                            <span title="Delete Plan" *ngIf="canDeletePlan && !item?.oldFloor"
                                                                class="fas fa-trash-alt thumb-remove"
                                                                (click)="removeFloor(item)"></span>
                                                            <img #img alt="" [src]="item.thumbnailAwsImageURL"  [appRetryImage]="item.thumbnailAwsImageURL"
                                                                *ngIf="item.thumbnailAwsImageURL;else defaultimage"
                                                                class="fallback img-responsive">
                                                            <ng-template #defaultimage>
                                                                <img alt="" src="{{cdnUrl}}assets/images/no-image.png"
                                                                    class="fallback img-responsive">
                                                            </ng-template>
                                                            <span *ngIf="!item.isArchived" title="{{item.name}}"
                                                                [ngStyle]="{'padding':item.name === ''? '14.5px':'5px 0px'}"
                                                                class="thumb-floor-name">{{item.name | wrap:35}}</span>
                                                            <span *ngIf="item.isArchived" title="{{item.name}}"
                                                                [ngStyle]="{'padding':item.name === ''? '14.5px':'5px 0px'}"
                                                                class="thumb-floor-name">{{item.name | wrap:35}}
                                                                (Archived)</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <input type="file" class="fileUpload" #buildingfileUpload style="display:none" id="file-upload-plan"
                                        (change)="onFileDialogChange($event)" accept=".jpeg,.jpg,.png,.pdf" />
                                    <a href="javascript:void(0);" class="btn btn-primary mrg-b-15"
                                        (click)="planSelectModal()">+ Add   Plan(s)
                                    </a>
                                </div>
                            </div>



                        </div>
                        <div class="building-info-wrapper">
                            <div class="row">

                                <div class="col-xl-12">
                                    <div class="row">
                                        <div class="col-sm-10 offset-sm-1">
                                            <div class="plan-details-col">
                                                <div class="row">

                                                    <div class="col-sm-4 "
                                                        *ngIf="(buildingDetails.floors|floorActiveInActive : showArchivedFloor)[0] !== 'NODATA' && selectedFloor">
                                                        <div class="form-group error-block">
                                                            <label>Plan Name
                                                                <sup *ngIf="!selectedFloor?.oldFloor">*</sup>
                                                            </label>
                                                            <input *ngIf="!selectedFloor?.oldFloor;else newFloor;"
                                                                maxlength="80" removeWhiteSpace type="text"
                                                                class="form-control" formControlName="floorName"
                                                                placeholder="Plan Name">
                                                            <ng-template #newFloor>
                                                                <span><br>{{selectedFloor.name}}</span>
                                                            </ng-template>
                                                            <so-control-messages [name]="'Plan Name'"
                                                                [control]="$any(buildingForm).controls['floorName']">
                                                            </so-control-messages>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-5"
                                                        *ngIf="(buildingDetails.floors|floorActiveInActive : showArchivedFloor)[0] !== 'NODATA' && selectedFloor">
                                                        <div class="form-group"
                                                            *ngIf="!selectedFloor?.oldFloor;else showNameOnly">
                                                            <label>Plan Image</label>
                                                            <div>
                                                                <so-file-upload [showFileName]="true"
                                                                    (uploadedFilesChange)="fileUploaded($event)"
                                                                    [uploadedFiles]="uploadedFiles"
                                                                    [errorMessage]="errorMessage"
                                                                    [isFromBuilding]="true"
                                                                    [selectedFloor]="selectedFloor"
                                                                    [fileUploadOptions]="fileUploadOptions"></so-file-upload>
                                                            </div>
                                                        </div>
                                                        <ng-template #showNameOnly>
                                                            <div class="form-group">
                                                                <label>Plan Image</label>
                                                                <div>
                                                                    <span>{{selectedFloor.fileName}}</span>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card overflow">
                                                <div class="row">
                                                    <div class="col-xl-12 text-center">
                                                        <div
                                                            *ngIf="buildingDetails.floors && (buildingDetails.floors|floorActiveInActive : showArchivedFloor)[0] !== 'NODATA';else noFloor">
                                                            <div class="edit-upload-img">
                                                                <div class="upload-main-img">
                                                                    <div *ngIf="selectedFloor && !selectedFloor?.oldFloor"
                                                                        class="overlay"></div>
                                                                    <div class="div-table">
                                                                        <div class="div-table-cell">
                                                                            <button
                                                                                *ngIf="selectedFloor && !selectedFloor?.oldFloor"
                                                                                type="button"
                                                                                (click)="openImageEditor(selectedFloor,$event)"
                                                                                class="btn btn-primary btn-new btn-edit-plan-modal">
                                                                                <i class="fas fa-marker"></i>Markup
                                                                                Plan</button>
                                                                            <img alt=""
                                                                                [src]="selectedFloor.awsImageURL"
                                                                                *ngIf="selectedFloor && selectedFloor.awsImageURL"
                                                                                class="fallback img-responsive center-block">
                                                                            <img alt=""
                                                                                src="{{cdnUrl}}assets/images/no-image.png"
                                                                                *ngIf="selectedFloor &&  (!selectedFloor || !selectedFloor.awsImageURL)"
                                                                                class="fallback img-responsive center-block">
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <ng-template #noFloor>
                                                            <div class="div-table no-floors">
                                                                <div class="div-table-cell">
                                                                    <p class="no-floor-message">{{NoPlanMessage}}</p>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-center "
                                                *ngIf="selectedFloor && (buildingDetails.floors|floorActiveInActive : showArchivedFloor)[0] !== 'NODATA'">
                                                <div class="track-plans">
                                                    <label>Created:</label>
                                                    <span class="created-time" *ngIf="selectedFloor.createdByUser"
                                                        title="{{selectedFloor.createdDateTime| date : 'M/dd/yy, h:mm a'}}">
                                                        {{selectedFloor.createdDateTime | datex :
                                                        'MM/DD/YYYY'}},</span>
                                                    <span (mouseout)="out()" *ngIf="selectedFloor.createdByUser"
                                                        (mouseover)="fillToolTipObj($event,selectedFloor.createdByUser)">
                                                        <div class="custom-tooltip">
                                                            <!-- {{equipmentDetails.createdBy}} -->
                                                            <span
                                                                class="created-by">{{selectedFloor.createdByUser.name}} {{selectedFloor.createdByUser.isActive === 0 ? ' (inactive)' : ''}}</span>
                                                            <so-tool-tip [toolTipObj]="toolTipObj">
                                                            </so-tool-tip>
                                                        </div>
                                                    </span>
                                                </div>
                                                <div class="ms-3 me-3 track-plans">
                                                    <label>Plan Image Updated:</label>
                                                    <span class="created-time"
                                                        *ngIf="selectedFloor.planImageUpdatedByUser"
                                                        title="{{selectedFloor.planImageModifiedDateTime| date : 'M/dd/yy, h:mm a'}}">
                                                        {{selectedFloor.planImageModifiedDateTime | datex :
                                                        'MM/DD/YYYY'}},</span>
                                                    <span (mouseout)="out()"
                                                        *ngIf="selectedFloor.planImageUpdatedByUser"
                                                        (mouseover)="fillToolTipObj($event,selectedFloor.planImageUpdatedByUser)">
                                                        <div class="custom-tooltip">
                                                            <span
                                                                class="created-by">{{selectedFloor.planImageUpdatedByUser.name}} {{selectedFloor.planImageUpdatedByUser.isActive === 0 ? ' (inactive)' : ''}}</span>
                                                            <so-tool-tip [toolTipObj]="toolTipObj">
                                                            </so-tool-tip>
                                                        </div>
                                                    </span>
                                                </div>

                                                <div class="track-plans">
                                                    <label>Plan Info Updated:</label>
                                                    <span class="created-time" *ngIf="selectedFloor.updatedByUser"
                                                        title="{{selectedFloor.modifiedDateTime| date : 'M/dd/yy, h:mm a'}}">
                                                        {{selectedFloor.modifiedDateTime | datex :
                                                        'MM/DD/YYYY'}},</span>
                                                    <span (mouseout)="out()" *ngIf="selectedFloor.updatedByUser"
                                                        (mouseover)="fillToolTipObj($event,selectedFloor.updatedByUser, 'left')">
                                                        <div class="custom-tooltip">
                                                            <span
                                                                class="created-by">{{selectedFloor.updatedByUser.name}} {{selectedFloor.updatedByUser.isActive === 0 ? ' (inactive)' : ''}}</span>
                                                            <so-tool-tip [displayRight]="true"
                                                                [toolTipObj]="toolTipObj">
                                                            </so-tool-tip>
                                                        </div>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- edit building end -->
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer ">
        <div class="row m-0" style="width: 100%">
            <div class="col-sm-6">
                <div class="progress-container">
                    <ul class="progressbar">
                        <li class="active">Site Information</li>
                        <li class="active">Buildings</li>
                        <li class="active">Plans</li>

                    </ul>
                </div>
            </div>
            <div class="col-sm-6 d-flex align-items-center justify-content-end">
                <button type="button" *ngIf="!isDisableBack && buildings.length > 0;else NobuildingBack"
                    [disabled]="btnService.getStatus() || isDisableBack"
                    [ngStyle]="{ 'cursor': btnService.getStatus() || isDisableBack ? 'not-allowed': 'pointer'}"
                    (click)="back()" class="btn btn-secondary">Back</button>
                <ng-template #NobuildingBack>
                    <button type="button" (click)="goToPrevious()" class="btn btn-secondary">Back</button>
                </ng-template>
                <button type="button" *ngIf="!disableNext && buildings.length > 0;else complete"
                    [disabled]="btnService.getStatus() || disableNext"
                    [ngStyle]="{ 'cursor': btnService.getStatus() || disableNext ?'not-allowed': 'pointer'}"
                    (click)="next()" class="btn btn-primary">Next</button>
                <ng-template #complete>
                    <button type="submit" [ngStyle]="{cursor:btnService.getStatus() ? 'not-allowed':'pointer'}"
                        [disabled]="btnService.getStatus()" class="btn btn-primary">Complete</button>
                </ng-template>
            </div>

        </div>
    </div>

    <div *ngIf="!(isFloorPlanAddedToAWS && isBuildingUpdated)">
        <div>
            <so-loader></so-loader>
        </div>
    </div>
</form>