/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input } from '@angular/core';
import { CommonService, Environment, ListService, Utilities } from '@SiteOwl/core';
import { TooltipModel } from './tool-tip.model';

@Component({
  selector: 'so-tool-tip',
  templateUrl: './tool-tip.component.html',
  styleUrls: ['./tool-tip.component.scss'],
})
export class ToolTipComponent {

  @Input() toolTipObj!: TooltipModel;
  @Input() isRoleToolTip!: boolean;
  @Input() displayRight!: boolean;
  @Input() displayLeft!: boolean;
  @Input() displayTop!: boolean;
  @Input() isCustomerToolTip!: boolean;
  @Input() isAdminToolTip!: boolean;
  @Input() noLiveSystemAccess!: boolean;
  @Input() customMessage!: boolean;
  @Input() defaultAdminTooltip!: boolean;
  @Input() customConnectionToolTip!: any;
  displayLeftTooltip = false;
  cdnUrl = this.env.cdnUrl;
  adminRole: any;
  private retryTimeout: any;

  // Static variables to prevent multiple calls
  private static isFetching = false;
  private static isFetched = false;

  constructor(private readonly env: Environment, private listService: ListService, private commonService: CommonService) {
    this.getAdminRole()
  }
  removePos(e: any) {
    e.stopPropagation();
  }

  getAdminRole() {
    this.adminRole = this.commonService.getLocalStorageObject('adminRole');
    if (this.adminRole || ToolTipComponent.isFetched) {
      return; // If already fetched, do nothing
    }

    if (ToolTipComponent.isFetching) {
      return;
    }

    ToolTipComponent.isFetching = true;
    if (this.adminRole === undefined || this.adminRole === null) {
      this.listService.getAdminSpecificRoleList().subscribe({
        next: (r: any) => {
          this.adminRole = Utilities.seperateRoleDescription(r);
          this.commonService.setLocalStorageObject('adminRole', this.adminRole);
          ToolTipComponent.isFetched = true; 
          ToolTipComponent.isFetching = false; 
          clearTimeout(this.retryTimeout); 
        },
        error: () => {
          ToolTipComponent.isFetching = false; 
        },
        complete: () => {
          if (!this.adminRole) {
            this.retryTimeout = setTimeout(() => this.getAdminRole(), 2000);
          }
        }
      })
    }
  }
}

