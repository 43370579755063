<div (click)="$event.stopPropagation();">
    <div class="modal-header d-flex align-items-center py-0">
        <ul class="modal-breadcrumb d-flex align-items-center">
            <li>
                <h1 class="modal-title pull-left">
                    View Image
                </h1>
            </li>
            <li *ngIf="file.fileName">
                {{file.fileName}}
            </li>
        </ul>
        <button data-dismiss="modal" (click)="closeModal()" aria-label="Close" class="close align-self-center" type="button">
            <em class="far fa-close"></em>
        </button>
    </div>
    <div class="modal-body view-modal-popup">
        <div *ngIf="pixieLoader">
            <so-loader [pixieLoader]="true"></so-loader>
        </div>
        <div class="d-flex position-relative">
            <!-- icon panel start -->
            <div class="icon-panel-editor card">
                <button (click)="zoomChangeWithButtonCall(false,true)" class="control-button" title="Zoom Out">
                    <span class="font-icon">
                        <em class="far fa-search-minus"></em>

                    </span>
                </button>
                <button (click)="zoomChangeWithButtonCall(true,true)" class="control-button" title="Zoom In">
                    <span class="font-icon">
                        <em class="far fa-search-plus"></em>

                    </span>
                </button>
                <button (click)="zoomChangeCall()" class="control-button" title="Fit to Screen">
                    <span class="font-icon">
                        <em class="far fa-expand-arrows-alt"></em>
                    </span>
                </button>
            </div>
            <!-- icon panel end -->
            <div class="content-wrapper">
                <ng-container *ngFor="let slide of equipment?.images; let index=index">
                    <div *ngIf="slide.isShow" class="card">
                        <div class="parent-container card-body" id="moveTicketParentContainer">
                            <div (imageLoaded)="viewImageLoaded()" soImageScrollDraggable
                                [imageScrollDraggable]="equipment" [fitToScreen]="fitToScreen"
                                (imageDragged)="imageDragged($event)" [imageZoom]="imageZoom"
                                (imageZoomChanged)="imageZoomChanged($event)" id="moveTicketFloorImageContainer"
                                class="upload-main-img"
                                [ngStyle]="{width: zoomValue, position: 'relative', display: 'block'}">
                                <div>
                                    <img alt="" [hidden]="pixieLoader" [src]="slide.awsImageURL"
                                        id="moveTicketFloorImageShadow" class="img-zoom">
                                </div>
                            </div>
                        </div>

                    </div>
                </ng-container>
                <!-- arrow start -->
                <div class="custom-slider-control" *ngIf="equipment?.images.length > 1">
                    <button (click)="getPreviousSlide(activeSlideIndex)" class="control-button previous"
                        title="Previous">
                        <span class="font-icon">
                            <em class="far fa-angle-left"></em>

                        </span>
                    </button>
                    <button (click)="getNextSlide(activeSlideIndex)" class="control-button next" title="Next">
                        <span class="font-icon">
                            <em class="far fa-angle-right"></em>
                        </span>
                    </button>
                </div>
                <!-- arrow end -->
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="d-flex align-items-center justify-content-between" style="width: 100%">
            <div class="footer-info view-mode">
                <div class="file-upload justify-content-start text-start m-0" *ngIf="file.id">
                    <div class="date-format form-group clearfix ms-0 me-1">
                        <label>Image Source:</label>
                        <div class="created-by image-source">
                            <ng-container *ngIf="file.imageSource">
                                <span title="{{file.imageSource.name}}"> {{file.imageSource.name}}</span>
                            </ng-container> 
                            <ng-container *ngIf="!file.imageSource">
                                <span title="Live Site">   Live Site</span>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="file-upload justify-content-start text-start m-0" *ngIf="file.id">
                    <div class="date-format form-group clearfix ms-0 me-1">
                        <div>
                            <label>Created:</label>
                            <div class="created-by"
                                title="{{selectedImage?.createdDateTime | date : 'M/dd/yy, h:mm a'}}">
                                {{createdTime}}
                            </div>
                            <div class="custom-tooltip">
                                <span class="created-by ms-1 me-2" (mouseout)="out()"
                                    (mouseover)="fillToolTipObj($event,selectedImage?.createdByUser)">
                                    {{createdBy}} {{selectedImage.createdByUser.isActive === 0 ? ' (inactive)' : ''}}
                                    <so-tool-tip [toolTipObj]="toolTipObj"></so-tool-tip>
                                </span>

                            </div>

                        </div>
                    </div>
                    <div class="date-format form-group clearfix ms-0 me-1">
                        <div>
                            <label>Updated:</label>
                            <div class="created-by"
                                title="{{selectedImage?.modifiedDateTime | date : 'M/dd/yy, h:mm a'}}">
                                {{updatedTime}}
                            </div>
                            <div class="custom-tooltip">
                                <span class="created-by ms-1 me-2" (mouseout)="out()"
                                    (mouseover)="fillToolTipObj($event,selectedImage?.updatedByUser)">

                                    {{updatedBy}} {{selectedImage.updatedByUser.isActive === 0 ? ' (inactive)' : ''}}
                                    <so-tool-tip [toolTipObj]="toolTipObj"></so-tool-tip>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="justify-content-end d-flex footer-cta">
                <button type="button" (click)="closeModal()" class="btn btn-secondary m-0">Close
                </button>
            </div>
        </div>
    </div>
</div>