import { Component, Input } from '@angular/core';
import { TooltipModel } from '../tool-tip/tool-tip.model';

@Component({
  selector: 'so-created-updated-date-time',
  templateUrl: './created-updated-date-time.component.html',
  styleUrls: ['./created-updated-date-time.component.scss'],
})
export class CreatedUpdatedDateTimeComponent {
  @Input('details') details: any;
  @Input('date') date: any;
  @Input('title') title: any;
  // timer: NodeJS.Timeout;
  toolTipObj: any;
  timer: any;

  constructor() { }

  fillToolTipObj(event: any, user: any) {
    event.stopPropagation();
    this.timer = setTimeout(() => {
      this.toolTipObj.name = user.name + (user.isActive === 0 ? ' (inactive)' : '');
      if (user.name === null || user.name === undefined) {
        this.toolTipObj.name = "(" + user.email + ")" + (user.isActive === 0 ? ' (inactive)' : '');
      }
      this.toolTipObj.employer = user.employer;
      this.toolTipObj.picture = user.thumbnailAwsImageURL;
      if (user.workNumber) {
        this.toolTipObj.work = user.workNumber;
      }
      if (user.cellNumber) {
        this.toolTipObj.mobile = user.cellNumber;
      }
      this.toolTipObj.email = user.email;
      clearTimeout(this.timer)
    }, 300);
    this.toolTipObj = new TooltipModel();
  }

  out() {
    clearTimeout(this.timer)
  }
}
